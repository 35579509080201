


.staff,
.students{

background-size:contain;

/*display: flex;*/


display: block;

height:250px;
width:100%;
margin:50px auto;
transform:translateZ(0);
}

.staff{
    background: url('./img/Banners/abount-banner-desk.jpg') no-repeat;
    background-size: contain;
}


.students{
background: url('./img/Banners/gallery-banner-desk.jpg') no-repeat;
background-size: contain;
}


@media screen and (max-width:767px){
    .students{
    height:85px !important;
    }
}
