.main{
    text-align: center;
    margin-top:30px ;
    margin-bottom:20px;
  }
  .flex_row{
    justify-content: center;
    display: flex;
    padding-top: 5px;
  }
  
.thumbnail{
  padding-left: 5px;
  
}

.thumbnail img.clicked{
    opacity: 0.4;
}
.clicked{
  border: 2px solid #f2584d !important ;
}
.btns{
  height: 40px;
  width: 40px;
  border-radius: 5px;
  background: grey;
}

@media screen and (max-width:767px) {
 
}