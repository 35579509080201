.tab{
    text-align: center;
    margin-top:50px;
}

.tab ul{
list-style-type: none;
}

.tab ul li.flex-fill {
    display: inline;
    color: #0066b4;
    text-align: center;
    background: transparent;
    border: 1px solid #0066b4;
    padding: 8px 12px;
    margin: 10px;
    cursor: pointer;;
}

.tab ul li.flex-fill:hover{
color:#fff;
cursor: pointer;;
background: #033051;
}

.tab ul li.flex-fill:active,
.tab ul li.flex-fill:visited{
    color:#fff ! important;
    cursor: pointer;;
    background: #033051 !important;
    }

    .updateToggle{
    background:red;
    }

.content{
display:none;
}

.show-content{
display:block;
}

@media screen and (max-width:767px) {
    .tab ul li.flex-fill{
    display:block !important;
    }

    .content{
        display:block;
        }
    
}