.footer-container {
    display   : flex;
    width     : 1147px;
    margin    : 50px auto 0 auto;
    height    : 300px;
    border-top: 2px solid #ccc;

}


ul li.ft-col-list,
ul li.social,
.pool-range-mob {
    list-style: none ! important;
}


.pool-range-desk {
    display: block;
}


.footer-col1,
.footer-col2,
.footer-col3,
.footer-col4 {
    display: block;
    float  : left;
    padding: 30px 15px;

}

.footer-col1 img {
    width  :35%;
    padding: 0 0 20px 0;

}

.footer-col1 {
    width       : 32%;
    margin-right: 30px;
}

.footer-col2,
.footer-col3,
.footer-col4 {
    width: 22%;
}

.ft-info,
h3.ft-col-tit,
ul li.ft-col-list a {
    font-size: 14px ! important;
}


.ft-info {
    color      : #262626;
    text-align : left;
    display    : flex;
    float      : left;
    width      : 70%;
    line-height: 1.6;
}



ul {
    padding: 0 ! important;

}


ul li.ft-col-list a {

    text-align      : left;
    line-height     : 2.2;
    /*color         : #898989;*/
    color           : #686868;
    text-transform  : initial ! important;
    font-weight     : 500;
    ;

}

ul li.ft-col-list a:hover {
    color: #1568b2 !important;
}

.privacy {
    margin-top: 32px;
}



.social a img {
    display   : flex;
    float     : left;
    background: #07aaea;
    border-radius: 100px;
    width     : 10%;
    padding   : 5px;
    margin    : 0 5px 0 0;
}

.social a:hover img {
    background: #ffc822 !important;

}