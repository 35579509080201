
a{
text-decoration: none !important;
}

em.abt{
    font-size: 20px;
  text-align: left;
  font-weight: 500;
  font-style: normal !important;
}

.position{
    font-size:12px;
    color:#007aff;
    text-transform: uppercase;
}
.inner-container {
    display: block;
    width  : 100%;
    margin : 10px auto;
}

.range-title h3{
text-align:center;
padding:50px auto;
}

ul li.ft-col-list a{
text-decoration: none ! important;
}

.frame-style{

   height:calc(530vh - 95px) ;
width:100% ! important;

     
}

.frame-style1{
    height:calc(100vh - 25px) ;
    width:100% ! important;
}

.frame-style2{
    height:calc(200vh - 25px) ;
    width:100% ! important;
}


.frame-style3{
    height:calc(130vh - 25px) ;
    width:100% ! important;
}

.header-banner {
    display: block;
    width  : 98%;
    margin : 20px auto;

    background-size: cover;
}

.header-banner img {
    width     : 100%;
    margin-top: 100px;
}


.header-banner-mob,
.header-caption-mob,
.Graphene_Banner-mob{
    display:none;
}


.inner-body-container {
    display: block;
    width  : 1147px;
    margin : auto;
}



sup.tit_super {
    font-size: 30px ! important;

}

sup.super {
    font-size: 15px ! important;
}

.colour-grid {
    display      : flex;
    float        : left;
    width        : 100%;
    margin-bottom: 15px;
}

.colour-col img {
    display: block;
    float  : left;
    width  : 95%;

    height: 190px;
}

.colur-name {
    display      : block;
    float        : left;
    position     : absolute;
    z-index      : 99;
    background   : #0000009c;
    padding      : 10px;
    text-align   : center;
    color        : #fff;
    margin       : 130px 0 auto 20px;
    border-radius: 5px;
}

.note {
    display: flex;
    width  : 98%;
    margin : 30px auto;

    background: #5a95c9;

}


.header-caption img {
    position: absolute;
    width   : 50%;
    top     : 150px;
}

.standard-grid {
    margin-bottom: 50px;
}

.year-logo {
    float      : right;
    margin-left: 20px;
}


.edge {
    float      : right;
    margin-left: 20px;
}

.certificate {
    width: 20%;
}


.sets-icon img {
    float: left;
    width: 7%;

    padding: 0 30px 20px 0;
}


.GrapheneBanner {
    display: block;
    float  : left;
    width  : 100%;
}

.GrapheneBanner img {
    float        : left;
    width        : 30%;
    display      : block;
    margin-bottom: 30px;
}

.inner_body {
    margin-top: 50px;

}


.pcg {
    float: left;
}

.watch-video {
    display: block;
    float  : left;
    width  : 100%;
    margin : 10px 0;
}

.watch-video a {
    display        : block;
    padding        : 20px;
    background     : #033051 !important;
    color          : #fff;
    cursor         : pointer;
    font-weight    : 500;
    text-transform : initial;
    margin         : 0 0 10px 0;
    float          : left;
    text-decoration: none;
    ;
}

.Graphene_Banner img,
.Layer_Banner img {
    display: flex;
    width  : 100%;
    float  : left;

}

.Graphene_Banner img {
    margin: 0 0 30px 0;
}

.Layer_Banner img {
    margin: 50px 0;
}




.test-container{
    display: flex;
    justify-content: center;
    gap:20px;
}


