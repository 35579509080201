.TwoColumn_Container{

    display:block;
    
    width:1170px;
    
    margin:auto;
    
    }
    
     
    .TwoColumn-left button{
        display: block;
        background: #004373;
        padding: 5px 15px;
        border: 0;
        color: #fff;
        text-transform: uppercase;
    }
    
    .TwoColumn-left{
    
    display:block;
    
    width:30%;
    
    padding:15px;
    
    float:left;
    
    }
    
     
    
    .TwoColumn-left img{
    
    width:100%;
    
    }
    
     
    
    @media screen and (max-width:767px) {
    .TwoColumn-left img{
    width:34% !important;
    }
   }