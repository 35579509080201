.welcome-col-left h1,
.welcome-col-left h2,
.col1 h1,
.col4 h1,
.col1 h5,
.col4 h5 {
    color: #fff ! important;

    line-height: 1.2;

}

.welcome-col-left h1,
.welcome-col-left h2 {
   
    text-align : center;
    padding-top: 40px;
}

.col1 h1 {
    text-align: center;
    padding   : 70px 0 0 0;
}

.col4 h1 {
    text-align: center;
    padding   : 30px 0 0 0;
}

.col1 h5,
.col4 h5 {
    font-weight: 400;
    text-align : center;
    padding    : 0 0 0 0;
    font-size  : 18px;
}

.welcome-col-left h1,
.col1 h1,
.col4 h1 {
    font-size  : 42px;
    font-weight: 500;

}

.welcome-col-left h2 {
    /*font-size:26px;*/
    font-size: 28px;
  font-weight: 400;
  padding: 30px 0 0 40px;
  text-align: left;

}

.best-sellers h1,
.best-sellers h4,
.best-sellers-home h1,
.best-sellers-home h4 {
    text-align  : center;
    /*color     : #404040 ! important;*/
}

.best-sellers h1,
.best-sellers-home h1,
.inner_body h1 {
    font-size     : 50px;
    font-weight   : 500;
    text-align    : center;
    padding-bottom: 0 ! important;
}

.inner_body h2 {
    font-size    : 26px;
    /*font-size  : 18px;*/
    text-align   : left;
    font-weight  : 600;
    /*color      : #404040 ! important;*/

}

.inner_body h4 {
    font-size  : 20px;
    text-align : left;
    font-weight: 500;


}

.inner_body h5 {
    font-size  : 18px;
    text-align : left;
    font-weight: 500;

}

.best-sellers h4,
.best-sellers-home h4,
.inner_body h3 {
    font-size  : 24px;
    font-weight: 400;
    text-align : center;
    padding-top: 0 ! important;
}

.inner_body p {

    line-height: 1.7;
}

.inner_body ul li {

    list-style-position: inherit;
    line-height        : 1.6;

    margin-left: 30px;
}

.graphene_tit ul li {
    width: 50%;
}

.inner_body h6 {
    color: transparent ! important;
}

.note p {
    color      : #fff;
    text-align : center ! important;
    font-size  : 13px;
    padding    : 20px 350px;
    line-height: 1.7;
}

.welocme-home h1,
.welocme-home h4{
text-align: center;

}

.welocme-home h4{
    font-weight:400 !important;
    line-height:1.6;
    padding-bottom:30px;
    font-size:24px;
}

h3.ft-col-tit {
    margin        : 0 0 20px 0 ! important;
    text-transform: uppercase;

    text-align : left;
    font-weight: bolder;

}

p.graphene-copy {
    width: 50%;
}