@media screen and (max-width: 3000px) and (min-width: 768px) {
    .mob-menu {
        display: none;
    }
}

.pool-range-mob {
    display: none;
}

.Gallery-grid-mob{
    display:none;
    }


@media screen and (max-width:767px) {

    .welcome-col-left{
    height:auto !important;
    }

    .welcome-col-left h2{
    line-height: 1.8 !important;
    }

    .welcome-4_col{
    height:auto !important;
    }

.TwoColumn-left{
width:92% !important;
}

.Gallery-grid{
display:none !important;
}

.Gallery-grid-mob{
display:block !important;
}
    .students{
    height:85px;
    }

    .pro-col1,
    .pro-col2,
    .pro-col3,
    .pro-col4,
    .pro-col5,
    .pro-col6,
    .pro-col7,
    .pro-col8,
    .pro-col9 {
        width : 95% ! important;
        margin: 0 15px 20px 15px ! important;
    }


    .fas.fa-bars {
        display     : block;
        /*background:url(./img/open.webp)no-repeat;*/
        width       : 30px;
        height      : 5px;
        margin-top  : 5px;
        background  : #0066b4 !important;
        margin-left : 80px;

    }

    .fas.fa-times {
        display   : block;
        background: #f2584d !important;
        width     : 30px;
        height    : 5px;
        margin-top: 5px;
        background: gray;
        ;
        margin-left: 80px;
    }

    #navbar {
        display        : flex ! important;
        flex-direction : column ! important;
        align-items    : flex-start;
        justify-content: flex-start;
        margin         : auto;
        position       : fixed;
        top            : 130px;
        right          : -350px;
        width          : 250px;
        background     : #fb023d !important;
        height         : 100vh;
    }


    #navbar.active {
        right: 0px;
    }

    #navbar li {

        margin: 20px 0 0 20px ! important;

    }

    #navbar li a {
        color: #fff ! important;

    }

    #mobile {
        display   : block ! important;
        margin-top: 40px;
    }

    #mobile i {
        font-size  : 24px;
        cursor     : pointer;
        /*padding  : 50px 0 0 70px;*/

        color: #0066b4 !important;
    }
    .ft-info{
    display:block !important;
    text-align:center !important;
    }

    .staff {
        background: contain ! important;
        height    : 82px ! important;
    }

    .year-logo {
        width: 25% ! important;
    }

    .inner_body p,
    .inner_body h2 {
        /* margin: 0 20px ! important;*/
        padding: 0 30px;
    }

    .inner_body h2 {
        font-size     : 24px ! important;
        /* padding-top: 30px ! important;*/
        padding       : 10px 30px;

    }

    .inner_body h5 {
        text-align : center ! important;
        line-height: 1.5;
    }

    .inner_body h4 {
        text-align: left !important;
    }

    .Graphene-container {
        background: #fff ! important;
    }

    p.graphene-copy,
    .graphene_tit ul li {
        width: 90% ! important;
    }

    ul.d-flex {
        display: none;
    }

    .GrapheneBanner img {
        width  : 60% ! important;
        display: block;
        float  : initial ! important;
        margin : 0 auto 40px auto !important;

    }

    .watch-video {
        float : inherit ! important;
        width : 50% ! important;
        margin: 10px auto ! important;
    }

    .sets-icon img {
        float  : left;
        width  : 8% ! important;
        padding: 0 20px 18px 20px ! important;
    }

    .inner-body-container {
        width: 100% ! important;
    }

    .certificate {
        width  : 50% ! important;
        margin : 0 auto 30px auto;
        float  : initial ! important;
        display: block;
    }

    .year-logo {
        float    : right ! important;
        margin   : 30px auto ! important;
        display  : block;
        max-width: 20%;
        ;
    }

    .edge {
        width : 90% ! important;
        margin: auto !important;
        float : initial !important;
    }

    .desk-menu {
        display: none;
    }

    .mob-menu {
        display: block;
    }

    .Graphene_Banner-mob,
    .Graphene_Banner-mob img {
        display        : block ! important;
        background-size: cover;
        width          : 100%;
        height         : auto;
    }

.range-title h3{
text-align: center;
}

    .header-banner-mob,
    .header-banner-mob img {
        display        : block ! important;
        background-size: cover;
        width          : 100%;
        height         : 275px;
    }

    .header-caption-mob {
        display: block ! important;
    }

    .header-caption-mob img {
        position: absolute;
        width   : 100%;
        top     : 110px;
        display : block ! important;
        height  : auto ! important;
    }

    .main-banner-mob {
        display                : block ! important;
        width                  : 100%;
        margin                 : 20px auto;
        height                 : 550px;
        background             : url('./img/Banners/Home-banner-mob.jpg') no-repeat;
        background-size        : cover;
        /*background-attachment: fixed;*/
    }

    .main-banner-mob img {
        width      : 100%;
        padding-top: 70%;
        display    : block;
        margin     : auto;
    }

    .best-sellers h1,
    .best-sellers-home h1,
    .inner_body h1 {
        font-size: 32px !important;
    }

    .welcome-col-left h2 {
        font-size: 24px ! important;
    }

    .main-banner,
    .Home-section1,
    .Home-section1 img,
    .col2,
    .col3,
    .pool-range-desk,
    .mobmenur-container,
    .header-banner,
    .Graphene_Banner {
        display: none ! important;
    }

    .colur-name {

        position  : relative ! important;
        z-index   : inherit;
        margin-top: -60px ! important;
    }

    .colour-grid {
        display      : block ! important;
        margin-bottom: 0 ! important;
    }

    .colour-col img {
        height       : 100% ! important;
        width        : 100% ! important;
        margin-bottom: 10px;
    }

    .note p {
        padding: 20px ! important;
    }

    .col1 h1,
    .col4 h1,
    .col4 h3,
    .col4 h5 {
        text-align: center;
    }

    .col1 h1 {
        padding: 50px 0 0 0 ! important;
    }

    .line_bt a,
    .download_btn a {
        margin: 0 auto 30px auto ! important;
    }

    .best-sellers-home,
    .best-sellers,
    .products-container,
    .products-container-home {
        width: 100% !important;
    }

    .Home-section1-mob,
    .Home-section1-mob img {
        display: block ! important;
        width  : 100%;
    }

    .best-sellers,
    .products-container,
    .welcome-4_col {
        width: 100% ! important;
    }

    .best-sellers h1,
    .inner_body h1 {
        font-size: 35px ! important;
    }

    .welcome-col-left,
    .welcome-col-right {
        width: 100% ! important;
    }

    .pool-range-mob {
        display      : block ! important;
        margin-bottom: 30px;
    }


    .frame-style {

        height: calc(1200vh - 95px) ! important;
        width : 100% ! important;


    }

    .frame-style1 {
        height: calc(120vh - 25px) ! important;
        width : 100% ! important;
    }

    .frame-style2 {
        height: calc(300vh - 25px) ! important;
        width : 100% ! important;
    }




    .footer-container {
        display: block ! important;
        width  : 100% ! important;
    }

    .footer-col1,
    .footer-col2,
    .footer-col3,
    .footer-col4 {
        width     : 90% ! important;
        display   : block;
        text-align: center ! important;
        margin    : 30px auto ! important;
        padding   : 0 ! important;
        float     : initial ! important;
    }



    .footer-col1 {
        margin-top: 30px auto ! important;
    }

    .footer-col4 {
        margin-bottom: 35px ! important;
    }

    .footer-col1 img {
        margin    : auto;
        text-align: center;
        width     : 30% ! important;
        display   : flex;
    }

    .ft-info {
        width     : 100% ! important;
        text-align: center ! important;
        float     : initial ! important;
        margin    : 30px auto ! important;
    }

    h3.ft-col-tit {
        text-align: center ! important;
        margin    : 0 ! important;
    }

    .privacy {
        margin: 30px auto ! important;
    }

    ul li.social {
        margin: 30px 0 30px 30%;
    }

    .social a img {
        width: 8% ! important;

    }
}

@media screen and (max-width:1400px) {
    .main-banner img {
        width      : 80% ! important;
        padding-top: 30% ! important;

    }

    .Home-section1 img {
        width: 90%;
    }

    .welcome-4_col {
        /*height: 320px ! important;*/
    }

    .welcome-col-left h1 {
        padding-top: 25% ! important;
        font-size  : 35px ! important;
    }

    .col1 h1,
    .col4 h1,
    .welcome-col-left h2 {
        font-size: 26px;
    }

    .line_bt a,
    .download_btn a {
        width: 50% ! important;
    }

    .col4 h1,
    .col4 h3 {
        padding-right: 30px ! important;
    }

    .welcome-col-left {
     /* height: 320px ! important;*/
    }


}

@media screen and (max-width: 1400px) and (min-width: 1025px) {

    .slide-steps img,
    .slide-steps h5,
    .slide-steps p {
        margin: 175px 0 10px 10px ! important;
    }

    .col1 h5,
    .col4 h5 {
        padding: 0 50px 0 30px;
    }

    .col1 h1 {
        padding: 28px 0 0 30px;
    }


}



@media screen and (max-width: 1365px) and (min-width: 768px) {

    .Home-section1 img {
        width: 100% !important;
    }

    .main-banner {
        height: auto !important;
    }

    #navbar li a {
        font-size: 12px !important;
    }

    #navbar li {
        padding: 10px 11px !important;
    }

    .best-sellers-home,
    .best-sellers,
    .products-container,
    .products-container-home {
        width: 100% !important;
    }

    .pro-col1,
    .pro-col2,
    .pro-col3,
    .pro-col4,
    .pro-col5,
    .pro-col6,
    .pro-col7,
    .pro-col8,
    .pro-col9 {
        width: 31.5% !important;
    }

    .col1 h1,
    .col4 h1,
    .welcome-col-left h2 {
        font-size: 20px !important;
    }

    .col1 h5,
    .col4 h5 {
        padding  : 0 25px 0 30px !important;
        font-size: 15px !important;
    }

    .line_bt a,
    .download_btn a {
        font-size: 14px !important;
        width    : 60% !important;

    }

    .slide-steps img {
        margin     : 180px 0 10px 10px !important;
        margin-left: 45% !important;
    }

    .section-left, .section-right{
        width:48% !important;
    }

    .inner_body{
        width:90% !important;
        margin:30px !important;
    }

    .formInput{
        width:45% !important;
    }

    .formInputAd, .formInputMsg{
    width:94% !important;
    }
}