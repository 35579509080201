.home-container {
    display: block;
    width  : 98%;
    margin : 10px auto;
}

.main-banner {
    display                : block;
    width                  : 100%;
    margin                 : 2px auto;
    height                 : 780px;
    background             : url('./img/Banners/Home-banner-desk.jpg') no-repeat;
    background-size        : cover;
    /*background-attachment: fixed;*/
}

.main-banner-mob,
.Home-section1-mob img,
.Home-section1-mob {
    display: none;
}

.main-banner img {
    width      : 55%;
    padding-top: 25%;
    display    : none !important;
    margin     : auto;

}

.Home-intro-section {
    display   : block;
    background: #1568b2;
    margin    : auto;
    padding:10px auto;
    border-top:2px solid #fff;
    color:#fff !important;
    float:left;

}


.pro-col1,
.pro-col2,
.pro-col3,
.pro-col4,
.pro-col5,
.pro-col6,
.pro-col7,
.pro-col8,
.pro-col9,
.pro-col10,
.pro-col11,
.pro-col12,
.pro-col13,
.pro-col14,
.pro-col15,
.pro-col16,
.pro-col17,
.pro-col18,
.pro-col19,
.pro-col20,
.pro-col21,
.pro-col22,
.pro-col23,
.pro-col24,
.pro-col25,
.pro-col26{
    display   : block;
    float     : left;
    /*width     : 23%;*/
    width     : 32%;
    text-align: center;

    margin-left: 15px;
}


.pro-col1 img,
.pro-col2 img,
.pro-col3 img,
.pro-col4 img,
.pro-col5 img,
.pro-col6 img,
.pro-col7 img,
.pro-col8 img,
.pro-col9 img,
.pro-col10 img,
.pro-col11 img,
.pro-col12 img,
.pro-col13 img,
.pro-col14 img,
.pro-col15 img,
.pro-col16 img,
.pro-col17 img,
.pro-col18 img,
.pro-col19 img,
.pro-col20 img,
.pro-col21 img,
.pro-col22 img,
.pro-col23 img,
.pro-col24 img,
.pro-col25 img,
.pro-col26 img {

    width: 35%;
}

.blue-bar {
    display   : block;
    padding   : 10px;
    background: #fff;
    color     : #222;
    text-align: center;
    margin-top: -5px;

}

.welcome-container {
    display: block;
    width  : 100%;
    margin : 30px auto;
    float  : left;

}

.col4 img{
display:block;
width:100%;
height:450px;
}

.mal-more a{
color:#07aaea;
}

.mal-more a:hover{
    color:#fff;
    }
    

.welcome-col-left,
.welcome-col-right {
    display: block;
    float  : left;
    width  : 50%;


}

.welcome-col-left p{
    color: #fff;
    padding: 10px 40px;
    line-height: 1.8;
}

.welcome-col-left {
    background:#004373;
    /*padding          : 306px 0 180px 0;*/
    background-size    : cover;
    background-position: center;
    height             : 450px;

}

.welcome-4_col {
    display     : block;
    float       : left;
    width       : 50%;
    background  : #05b4c9;
    text-align  : center;
    /*padding   : 195px 0;*/
    height      : 450px;
}

.col1 {
    background: #07aaea;
}

.col2 {
    
    background-size: cover;
}

.col3 {
  
    background-size: cover;
}

.col4 {
    background: #05b4c9;
}

.Home-section1 {
    display   : block;
    background: #0e2a4b;
    margin    : auto;

}

.Home-section1 img {
    height : auto;
    margin : auto;
    display: block;
    width  : 1147px;
}

.best-sellers-home,
.best-sellers,
.products-container,
.products-container-home {
    margin : auto;
    width  : 1147px;
    display: block;

}

.best-sellers,
.products-container{
float:left;
}

.line_bt a,
.download_btn a {
    display       : block;
    border        : 1px solid #fff;
    padding       : 10px;
    color         : #fff;
    text-transform: uppercase;
    margin:auto;
 

    text-decoration: none;

}


.line_bt a {
    width: 30%;
}

.download_btn a {
    width: 40%;
}

