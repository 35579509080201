#mobile {
    display: none;
}

#navbar {
    display: block;
    padding: 20px 10px;
    margin : auto;
}



#navbar li {
    list-style: none;
    display   : inline-block;
    padding   : 10px 12px;
    text-align: center;
    margin-top: 30px;
}

#navbar li a {
    text-decoration: none;
    text-transform : uppercase;
    color          : #222;
    font-size      : 14px;
    font-weight    : 600;
}

#navbar li.quote,
#navbar li.quote a {
    background: #f2584d !important;
    color     : #fff ! important;
}