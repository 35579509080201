.header-container{
    display: block;
    width:100%;
    /*margin:10px auto 0 auto;*/
    background:#ffffff;
    position: fixed;
   margin-top:-25px;
   
   z-index:20000;
}

.header-logo-navbar{
    display: flex;
    width:1147px;
    margin:30px auto 0 auto;
}

.header-left,
.header-right{
display:block;
float:left;
}

.header-left{
width:26%;

}

.header-left img{
width:40%;

}

.header-right{
    width:90%;
    
}